<template lang="pug">
  v-card(:to="to")
    v-card-text.pa-0.conteudo
      v-container.pa-0
        .layout.row.ma-0
          .md6.sm6.xs6.flex.white
            .layout.column.ma-0.justify-center.align-center.conteudo
              v-icon(size='56px', color='#505050') {{icon}}
          .md6.sm6.xs6.flex.text-sm-center.py-3.black--text.conteudo(:class='color')
            .headline.font-weight-bold {{ title }}
            span.caption.font-weight-medium {{ subTitle }}
</template>

<script>
export default {
  props: {
    icon: String,
    title: Number,
    subTitle: String,
    color: String,
    to: String
  }
};
</script>

<style lang="scss" scoped>
.conteudo{
  min-height: 85px;
  height: auto;
}
</style>