<template lang="pug">
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap)
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="verified_user", :title="auditados.length", sub-title="Auditados Ativos", color="primary", to="/auditado")
      v-flex(lg3, sm6, xs12)
        mini-statistic(icon="folder", :title="auditorias.length", sub-title="Auditorias", color="primary", to="/auditoria")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="description", :title="questionarios.length", sub-title="Questionários Ativos", color="primary", to="/questionario")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="playlist_add_check", :title="tipos_identificadores.length", sub-title="Tipos de Identificadores Ativos", color="primary", to="/tipo-identificador")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="playlist_add_check", :title="tipos_questionarios.length", sub-title="Tipos de Questionários Ativos", color="primary", to="/tipo-questionario")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="feedback", :title="categorias_feebacks.length", sub-title="Categorias de Feedbacks Ativas", color="primary", to="/categoria-feedback")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="feedback", :title="feebacks.length", sub-title="Feedbacks Ativos", color="primary", to="/feedback")
      v-flex(v-if="$root.user.permissao == 0", lg3, sm6, xs12)
        mini-statistic(icon="people_alt", :title="usuarios.length", sub-title="Usuários Ativos", color="primary", to="/usuario")
</template>

<script>
import _ from 'lodash';
import MiniStatistic from '@/components/MiniStatistic';

export default {
  components: {
    MiniStatistic
  },
  data () {
    return {
      auditados: [],
      auditorias: [],
      questionarios: [],
      categorias_feebacks: [],
      feebacks: [],
      categorias_questoes: [],
			usuarios: [],
			tipos_identificadores: [],
			tipos_questionarios: [],
    };
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
  },
  mounted () {
    this.getAuditados();
    this.getAuditorias();
    // this.getCategoriasQuestoes();
    this.getCategoriasFeedbacks();
    this.getFeedbacks();
    this.getQuestionarios();
    this.getTiposIdentificadores();
    this.getTiposQuestionarios();
    this.getUsuarios();
  },
  methods: {
    async getAuditados () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/auditado', { params : { ativo : 1 } });
        vm.auditados = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getAuditorias () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/auditoria', { params : { usuario_id: this.user.permissao == 0 ? null : this.user.id }});
        vm.auditorias = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getQuestionarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/questionario', { params : { ativo : 1 } });
        vm.questionarios = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getCategoriasFeedbacks () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/categoria-feedback', { params : { ativo : 1 } });
        vm.categorias_feebacks = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getFeedbacks () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/feedback', { params : { ativo : 1 } });
        vm.feebacks = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getCategoriasQuestoes () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/categoria-questao', { params : { ativo : 1 } });
        vm.categorias_questoes = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getUsuarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/usuario', { params: { fields: "usuario_licencas" } });
        vm.usuarios = _.reject(response.data.rows, { id : vm.$root.user.id});
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getTiposIdentificadores () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/tipo-identificador');
        vm.tipos_identificadores = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getTiposQuestionarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/tipo-questionario', { params: { padrao : 0 } });
        vm.tipos_questionarios = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
  }
};
</script>

<style lang="sass" scoped>

</style>
